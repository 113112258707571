import React, { useEffect } from "react"
import { DataEmpty } from "@/app/components/data-empty"
import { Button } from "@/app/components/ui/button"
import {
  useQueryGetListFriend,
  useQueryGetListFriendWithSearch
} from "@/app/services/query-fn/user/user-friend-query"
import { useTranslation } from "next-i18next"
import { useInView } from "react-intersection-observer"
import { useMutatonUnFriend } from "@/app/services/query-fn/user/user-friend-mutation"
import { IUser } from "@/app/interfaces"
import { useQueryClient } from "@tanstack/react-query"
import { QUERY_KEY } from "@/app/configs"
import { OnlyShowAvatar } from "@/app/components/app-avatar/OnlyShowAvatar"

export const ListFriend = ({
  active = false,
  searchValue = ""
}: {
  active: boolean
  searchValue: string
}) => {
  const { t } = useTranslation()
  const { data: listFriendWithoutSearch, fetchNextPage: fetchWithoutSearch } =
    useQueryGetListFriend(!!active, searchValue)

  const { data: listFriendWithSearch, fetchNextPage: fetchWithSearch } =
    useQueryGetListFriendWithSearch(!!active, searchValue)
  const { ref, inView } = useInView()
  const { mutate } = useMutatonUnFriend()

  const queryClent = useQueryClient()

  const onUnFriend = (user: IUser) => {
    mutate(
      {
        friend_id: user?.id
      },
      {
        onSuccess(data, variables, context) {
          queryClent.refetchQueries({
            queryKey: [QUERY_KEY.LIST_FRIEND]
          })
          queryClent.refetchQueries({
            queryKey: [QUERY_KEY.LIST_SUGGEST_FRIEND]
          })
          queryClent.refetchQueries({
            queryKey: [QUERY_KEY.PROFILE_INFO]
          })
        }
      }
    )
  }
  const listFriendWithoutAuthenticate = () => {
    const listFriend = !!searchValue
      ? listFriendWithSearch
      : listFriendWithoutSearch

    return listFriend?.pages?.flat()?.length ? listFriend?.pages?.flat() : []
  }

  useEffect(() => {
    if (inView) {
      searchValue ? fetchWithSearch() : fetchWithoutSearch()
    }
  }, [fetchWithoutSearch, fetchWithSearch, searchValue, inView])

  return (
    <>
      {listFriendWithoutAuthenticate()?.length ? (
        listFriendWithoutAuthenticate().map((item, index) => (
          <div
            className="flex items-center justify-between w-full"
            key={item?.id}
          >
            <div className="flex flex-1 gap-4">
              <OnlyShowAvatar size={40} avatarUser={item} />
              <p className="flex items-center text-base Nunito-700 text-bg-01 dark:text-white">
                {item?.fullname}
              </p>
            </div>
            {!item?.authentic && (
              <Button
                variant="none"
                className="outline-none bg-transparent text-neutral-06 h-[27px] px-[12px] flex items-center justify-center border-[1px] border-neutral-06 rounded-[8px]"
                onClick={() => onUnFriend(item)}
              >
                {t("profile.unfriend")}
              </Button>
            )}
          </div>
        ))
      ) : (
        <div className="w-full flex items-center justify-center h-[200px]">
          <DataEmpty title={t("profile.no_data")} />
        </div>
      )}
      <button ref={ref} className="opacity-0">
        Load more
      </button>
    </>
  )
}
