import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger
} from "@/app/components/ui/dropdown-menu"
import {
  NOTIFICATION_TYPE,
  QUERY_KEY,
  TIMEOUT_SPIN,
  isClient
} from "@/app/configs"
import { ListNotification } from "@/app/containers/notification/ListNotification"
import { AppContext, MobileContext } from "@/app/context"
import { useSocket } from "@/app/hooks/useSocket"
import { INotice } from "@/app/interfaces/INotice"
import { useQueryGetListNotification } from "@/app/services/query-fn/notification/notification-query"
import { useQueryClient } from "@tanstack/react-query"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import { memo, useContext, useState } from "react"
import { IconNotice } from "../icon-tsx/IconNotice"
import { IconReadAll } from "../icon-tsx/IconReadAll"
import { useWindowSize } from "@/app/hooks"
import { LoadingBtnComponent } from "../loading-btn"
import useGAEventsTracker from "@/app/hooks/useGAEventsTracker"
import { useToast } from "../ui/use-toast"

export const listType = [
  {
    title: "notification.all",
    value: "all"
  },
  {
    title: "notification.unread",
    value: "1"
  }
]

const NotificationHeader = () => {
  const router = useRouter()
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const [width] = useWindowSize()
  const { toast } = useToast()
  // const { isMobile } = useContext(MobileContext)
  const isMobile = !width || width < 768
  const token = isClient ? window.localStorage.getItem("token") || "" : ""
  const { unReadCount, setUnReadCount, themeApp } = useContext(AppContext)
  const [noticeId, setNoticeId] = useState<string | undefined>(undefined)
  const [isShowNotices, setIsShowNotices] = useState<boolean>(false)
  const [noticeType, setNoticeType] = useState<string>("all")
  const [loadingReadAll, setLoadingReadAll] = useState(false)
  const {
    data: listNoti,
    isFetching,
    fetchNextPage
  } = useQueryGetListNotification(setUnReadCount, noticeType)
  const GAEventsTracker = useGAEventsTracker("Spin")
  const GAEventsTrackerNoti = useGAEventsTracker("Notification")
  const GAEventsTrackerReward = useGAEventsTracker("Reward")
  const handleRefetch = () => {
    queryClient.refetchQueries({
      queryKey: [QUERY_KEY.NOTIFICATION_LIST, { noticeType }]
    })
  }

  const handleEventNotification = (data: INotice) => {
    if (data?.type === "purchase") {
      GAEventsTracker("Store_Buy_ReceiveSpin", "Thanh toan thanh cong")
    }

    if (data?.type === "spin") {
      setTimeout(() => {
        handleRefetch()
      }, TIMEOUT_SPIN)
    } else {
      handleRefetch()
    }

    if (data?.type === "accept_request_friend") {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.PROFILE_INFO],
        refetchType: "active"
      })
    }

    if (data?.type === NOTIFICATION_TYPE.TASK) {
      GAEventsTrackerReward("Mission_Do_success", data?.content_id?.task_id)
    }
    if (
      data?.type === NOTIFICATION_TYPE.TASK ||
      data?.type === NOTIFICATION_TYPE.TOPUP_GAME_EVENT
    ) {
      toast({
        variant: "success",
        title:
          "Bạn đã hoàn thành xong nhiệm vụ. Vui lòng kiểm tra thông báo để nhận quà"
      })
    }
  }

  useSocket({
    event: "connection",
    handleEvent: (msg: string) => {
      // console.log(msg)
    },
    dependences: [listNoti?.pages?.flat()],
    handleEventNotification
  })

  useSocket({
    event: "inbox",
    type: "emit",
    dependences: [noticeId],
    handleEvent: !!noticeId ? [token, noticeId] : ""
  })

  const handleOpenNotificationPage = () => {
    !isMobile && setIsShowNotices(!isShowNotices)
    isMobile && router.push(`/notifications`)
    GAEventsTrackerNoti("Notification_open")
    setUnReadCount(0)
  }

  const handleReadAllNotification = () => {
    // setIsShowNotices(false)
    setLoadingReadAll(true)
    setNoticeId("all")

    setTimeout(() => {
      setLoadingReadAll(false)
      handleRefetch()
    }, 3000)
  }

  return (
    <div>
      <DropdownMenu
        open={isShowNotices}
        onOpenChange={handleOpenNotificationPage}
      >
        <DropdownMenuTrigger asChild>
          <div className="cursor-pointer relative ml-[24px] md:ml-0">
            <IconNotice color={"#7673E6"} />
            {!isShowNotices && unReadCount > 0 && (
              <span className="absolute z-2 bottom-[60%] left-[50%] rounded-full Nunito-600 text-white text-[10px] bg-main-red px-1 text-center">
                {unReadCount}
              </span>
            )}
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          align="end"
          className="w-[400px] dark:bg-dark-mode-box p-[16px] rounded-[16px] shadow-[0px_3px_30px_0px_rgba(0,0,0,0.15)] space-y-[12px]"
        >
          <h1 className="text-[18px] Nunito-700 leading-[25px]">
            {t("notification.notifications")}
          </h1>

          <div className="flex gap-[8px]">
            {listType.map((item) => {
              return (
                <div
                  key={item.value}
                  className={`px-[12px] h-[28px] flex items-center text-[14px] cursor-pointer border rounded-[12px] leading-[16px] Nunito-600 ${
                    noticeType === item.value
                      ? "border-main-01 bg-main-01 text-white"
                      : "border-black/10 dark:border-white/10 text-neutral-03 dark:text-main-white"
                  }`}
                  onClick={() => {
                    setNoticeType(item.value)
                  }}
                >
                  {t(item.title)}
                </div>
              )
            })}
          </div>

          <ListNotification
            setNoticeId={setNoticeId}
            listNoti={listNoti}
            isFetching={isFetching}
            fetchNextPage={fetchNextPage}
            className="h-[350px] xl:h-[504px]"
            setIsShowNotices={setIsShowNotices}
            handleRefetch={handleRefetch}
          />

          <div
            className={`flex items-center gap-[8px] text-main-01 dark:text-main-white text-[12px] Nunito-600 w-fit ${
              loadingReadAll ? "" : "cursor-pointer"
            }`}
            onClick={handleReadAllNotification}
          >
            <IconReadAll fill={themeApp === "dark" ? "#FAF8FF" : ""} />
            {t("notification.mark_read_all")}
            {loadingReadAll && <LoadingBtnComponent />}
          </div>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}

export default memo(NotificationHeader)
