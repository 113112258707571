import { IconCalendar } from "@/app/components/icon-tsx/IconCalendar"
import { Button } from "@/app/components/ui/button"
import { Calendar } from "@/app/components/ui/calendar"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger
} from "@/app/components/ui/dropdown-menu"
import { ScrollArea } from "@/app/components/ui/scroll-area"
import { DATE_FORMAT, END_YEAR, FROM_YEAR } from "@/app/configs"
import { CustomSelectMonthYear } from "@/app/containers/create-post/add-on/CustomSelectMontYear"
import moment from "moment"
import { useTranslation } from "next-i18next"
import React, { useMemo } from "react"
import { useFormContext } from "react-hook-form"

interface EndTimeProps {
  open: boolean
  onChange: () => void
}
export const EndTimeFilter: React.FC<EndTimeProps> = ({ open, onChange }) => {
  const { watch, setValue } = useFormContext()
  const { t } = useTranslation()
  const selectEndTime = watch("end_date", undefined)

  const handleDaySelectTime = (date: Date | undefined) => {
    setValue("end_date", date)
    onChange()
  }
  const content = useMemo(() => {
    return (
      <DropdownMenu open={open} onOpenChange={onChange}>
        <DropdownMenuTrigger asChild>
          <Button
            variant="none"
            className="bg-disable w-full text-neutral flex justify-between items-center py-[8px] dark:text-main-white border-b dark:border-neutral-07 rounded-none"
          >
            <span>
              {selectEndTime
                ? moment(selectEndTime).format(DATE_FORMAT)
                : t("profile.to_date")}
            </span>
            <IconCalendar />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-[276px]" align="end">
          <ScrollArea className="h-fit">
            <Calendar
              mode="single"
              selected={selectEndTime}
              onSelect={handleDaySelectTime}
              className="p-0 rounded-md"
              showOutsideDays
              captionLayout="dropdown"
              fromYear={FROM_YEAR}
              toYear={END_YEAR}
              components={{
                Head: () => <CustomSelectMonthYear />
              }}
            />
          </ScrollArea>
        </DropdownMenuContent>
      </DropdownMenu>
    )
  }, [open])
  return <>{content}</>
}
