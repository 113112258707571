import React, { useContext, useEffect, useState } from "react"
import { IconGame } from "../icon-tsx/IconGame"
import { IconNews } from "../icon-tsx/IconNews"
import { IconStore } from "../icon-tsx/IconStore"
import { CreateType, MENU_TAB, OPEN_MODAL, isClient } from "@/app/configs"
import { useRouter } from "next/router"
import { useTranslation } from "next-i18next"
import Link from "next/link"
import { AppContext } from "@/app/context"
import { IconHamburger } from "../icon-tsx/IconHamburger"
import { IconGameFill } from "../icon-tsx/IconGameFill"
import { IconNewsFill } from "../icon-tsx/IconNewsFill"
import { IconStoreFill } from "../icon-tsx/IconStoreFill"
import useGAEventsTracker from "@/app/hooks/useGAEventsTracker"

export const SideBarMobile = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const { userInfo, setOpenModal } = useContext(AppContext)
  const [isLogin, setIsLogin] = useState(false)
  const { themeApp } = useContext(AppContext)
  const GAEventsTracker = useGAEventsTracker("Store")
  const GAEventsTrackerGame = useGAEventsTracker("Game")
  const GAEventsTrackerNews = useGAEventsTracker("News")
  const token = isClient ? window.localStorage.getItem("token") : null
  useEffect(() => {
    if (!!token) {
      setIsLogin(true)
    } else {
      setIsLogin(false)
    }
  }, [token])

  const listTab = [
    {
      icon:
        router.pathname === MENU_TAB.STORE_TAB ? (
          <IconStoreFill fill={themeApp === "dark" ? "#FAF8FF" : ""} />
        ) : (
          <IconStore
            fill={themeApp === "dark" ? "#FAF8FF" : ""}
            className="icon-stroke"
          />
        ),
      title: t("common.side_bar_store"),
      path: MENU_TAB.STORE_TAB
    },
    {
      icon:
        router.pathname === MENU_TAB.GAME_TAB ? (
          <IconGameFill fill={themeApp === "dark" ? "#FAF8FF" : ""} />
        ) : (
          <IconGame
            fill={themeApp === "dark" ? "#FAF8FF" : ""}
            className="icon-stroke"
          />
        ),
      title: t("common.game_tab_title"),
      path: MENU_TAB.GAME_TAB
    },

    {
      icon: (
        <div
          className="w-[54px] h-[40px] rounded-[50px] bg-main-01 flex items-center justify-center -translate-y-[20px]"
          style={{
            boxShadow: "0px 4px 20px 0px rgba(118, 115, 229, 0.40)"
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M12 6V18M18 12L6 12"
              stroke="#FAF8FF"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      ),
      action: true,
      title: "",
      path: MENU_TAB.SELECT_CREATE_POST
    },
    {
      icon:
        router.pathname === MENU_TAB.NEWS_TAB ? (
          <IconNewsFill fill={themeApp === "dark" ? "#FAF8FF" : ""} />
        ) : (
          <IconNews
            fill={themeApp === "dark" ? "#FAF8FF" : ""}
            className="icon-fill"
          />
        ),
      title: t("common.news_tab_title"),
      path: MENU_TAB.NEWS_TAB
    }
  ]

  return (
    <div
      className={`fixed md:hidden bottom-0 left-0 z-[30] bg-white flex items-center h-[64px] w-full pb-[12px] px-[24px] dark:bg-mobile-bg`}
      style={{
        boxShadow: "0px 3px 30px 0px rgba(0, 0, 0, 0.15)"
      }}
    >
      <ul className="list-none w-full justiy-evenly flex flex-start Nunito-600 text-[14px] h-full items-center">
        {listTab.map((menuItem, index) => {
          if (menuItem.path === MENU_TAB.SELECT_CREATE_POST) {
            if (!userInfo?.authentic) {
              return null
            } else {
              return (
                <li
                  key={index}
                  className={`sidebar_link w-full justify-center h-full ${
                    router.pathname == menuItem.path
                      ? "border-t-[2px] border-main-01 sidebar_link--active"
                      : "border-t-[2px] border-white dark:border-mobile-bg"
                  }`}
                >
                  <div
                    className="flex items-center justify-center"
                    onClick={
                      () =>
                        router.push(
                          `/create-post?type=${CreateType.CREATE_POST}`
                        )
                      // setOpenModal(OPEN_MODAL.SELECT_CREATE_POST_MODAL)
                    }
                  >
                    {menuItem.icon}
                  </div>
                </li>
              )
            }
          } else
            return (
              <li
                key={index}
                className={`sidebar_link w-full justify-center h-full ${
                  router.pathname == menuItem.path
                    ? "border-t-[2px] border-main-01 sidebar_link--active"
                    : "border-t-[2px] border-white dark:border-mobile-bg"
                }`}
                onClick={() => {
                  if (menuItem.path === MENU_TAB.STORE_TAB) {
                    GAEventsTracker("Click_Store_Tab")
                  }

                  if (menuItem.path === MENU_TAB.GAME_TAB) {
                    GAEventsTrackerGame("Click_Games_Tab")
                  }

                  if (menuItem.path === MENU_TAB.NEWS_TAB) {
                    GAEventsTrackerNews("Click_Games_Tab")
                  }
                }}
              >
                <Link
                  className="flex items-center justify-center"
                  href={menuItem.path}
                >
                  {menuItem.icon}
                </Link>
              </li>
            )
        })}

        <li
          className={`sidebar_link w-full justify-center h-full ${
            [MENU_TAB.OPTION_PROFILE, MENU_TAB.USER]?.includes(router.pathname)
              ? "border-t-[2px] border-main-01 sidebar_link--active"
              : "border-t-[2px] border-white dark:border-mobile-bg"
          }`}
        >
          {isLogin ? (
            <div
              className="flex items-center justify-center"
              onClick={() => router.push(MENU_TAB.OPTION_PROFILE)}
            >
              <IconHamburger
                fill={
                  [MENU_TAB.OPTION_PROFILE, MENU_TAB.USER]?.includes(
                    router.pathname
                  )
                    ? "#7673E5"
                    : themeApp === "dark"
                    ? "#FAF8FF"
                    : ""
                }
              />
            </div>
          ) : (
            <div onClick={() => setOpenModal(OPEN_MODAL.LOGIN_MODAL)}>
              <IconHamburger
                fill={
                  router.pathname === MENU_TAB.NEWS_TAB
                    ? "#7673E5"
                    : themeApp === "dark"
                    ? "#FAF8FF"
                    : ""
                }
              />
            </div>
          )}
        </li>
      </ul>
    </div>
  )
}
