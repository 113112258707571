/* eslint-disable @next/next/no-img-element */
import moment from "moment"
import React, { FC, useCallback, useContext, useMemo } from "react"
import { Button } from "../ui/button"
import { IconDirection } from "../icon-tsx/IconDirection"
import { useRouter } from "next/router"
import {
  createSlugNameDetail,
  extractText,
  getCdnImage
} from "@/app/helpers/function"
import { IPostCard } from "@/app/interfaces/IPost"
import { useTranslation } from "next-i18next"
import { DATE_FORMAT_3, DATE_FORMAT_HOUR, isClient } from "@/app/configs"
import { AppContext } from "@/app/context"
import useGAEventsTracker from "@/app/hooks/useGAEventsTracker"
import Image from "next/image"
import Link from "next/link"
interface PostEventProps {
  event: IPostCard
  onJoinEvent?: (id: string) => void
}

export const PostEvent: FC<PostEventProps> = ({ event, onJoinEvent }) => {
  const { t } = useTranslation()
  const router = useRouter()
  const { userInfo } = useContext(AppContext)
  const GAEventTracker = useGAEventsTracker("Event")
  const token = isClient ? window.localStorage.getItem("token") : null

  const navigateUrl = useCallback(() => {
    const isNotPublish =
      event?.post?.public_at * 1000 > Number(new Date().getTime())
    const id = !!isNotPublish
      ? event?.post?.id
      : createSlugNameDetail(event?.post?.title, event?.post?.id)
    const type = "event"
    const pathname = !!isNotPublish
      ? `/preview/${type}/${id}`
      : `/detail/${type}/${id}`
    return pathname
  }, [event])

  const onNavigateToLinkEvent = () => {
    if (!!event?.post?.link_event) {
      const link =
        event?.post?.link_event?.includes(
          process.env.NEXT_PUBLIC_EVENT_DOMAIN || ""
        ) && token
          ? `${event?.post?.link_event}?token=${token}`
          : event?.post?.link_event
      router.push(String(link))
    } else {
      GAEventTracker("Click_event_banner", event?.post?.thumbnail)
      router.push(navigateUrl())
    }
  }

  const getTypeEvent = (
    start_time: number | undefined,
    end_time: number | undefined
  ) => {
    const moment = Number(new Date().getTime())
    if (moment > Number(end_time) * 1000 || !start_time || !end_time) {
      return ""
    }

    if (
      moment > Number(start_time) * 1000 &&
      moment < Number(end_time) * 1000
    ) {
      return t("news.happenning_event")
    }
    if (moment < Number(start_time) * 1000) {
      return t("news.incoming_event")
    }
  }

  const onSubmitReminderOrJoin = () => {
    onJoinEvent && onJoinEvent(event?.post?.id)
  }

  const renderContent = useMemo(() => {
    return typeof document === "undefined" ? null : event?.post?.content

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event?.post?.content])
  return (
    <div
      key={event?.post?.id}
      className="flex-col md:flex-row flex gap-0 md:gap-[24px] mb-[24px] md:mb-[32px] w-full border-b border-black/10 dark:border-neutral-03 lg:border-0"
    >
      <div className="grid grid-cols-12 gap-[24px] max-w-full">
        <div className="min-w-full col-span-12 grid grid-cols-12 gap-[12px] md:gap-[24px]">
          <div className="flex flex-col order-1 w-full col-span-12 pb-4 lg:pb-0 md:order-0 md:col-span-8">
            {!!event?.post?.public_at &&
            event?.post?.public_at * 1000 > Number(new Date().getTime()) ? (
              <p className="text-[12px] Nunito-400 text-main-01 w-fit first-letter:uppercase">
                {t("news.public_time")}&nbsp;&nbsp;
                {moment(Number(event?.post?.public_at) * 1000).format(
                  DATE_FORMAT_HOUR
                )}
              </p>
            ) : (
              <p className="text-[12px] Nunito-400 text-bg-01 dark:text-white w-fit first-letter:uppercase">
                {moment(
                  Number(event?.post?.start_time ?? event?.post?.public_at) *
                    1000
                ).format(DATE_FORMAT_3)}
              </p>
            )}
            <div className="relative flex justify-between">
              <Link href={navigateUrl()}>
                <h3 className="w-fit max-w-full text-sm md:text-[18px] sd:text-[20px] text-bg-01 Nunito-700 line-clamp-1 leading-normal mt-[8px] dark:text-white overflow-hidden cursor-pointer">
                  {event?.post?.title}
                </h3>
              </Link>
            </div>
            <div
              className="text-[14px] Nunito-500 line-clamp-3 dark:text-white my-[16px] w-fit max-w-[800px] break-words"
              dangerouslySetInnerHTML={{
                __html: extractText(`${renderContent}...` || "") ?? ""
              }}
            ></div>

            <div className="flex md:items-center flex-col md:flex-row gap-2 md:gap-[40px] flex-wrap">
              {Number(event?.post?.end_time) * 1000 >
                Number(new Date().getTime()) &&
                event?.author?.id !== userInfo?.userid && (
                  <>
                    {event?.post?.joined ? (
                      <Button
                        variant="none"
                        className="bg-button text-main-01 dark:bg-[#efefff]/[.1] py-[6px] Nunito-600 text-sm cursor-default w-fit rounded-[12px] max-h-[32px]"
                      >
                        {t("news.joined")}
                      </Button>
                    ) : (
                      <Button
                        variant="none"
                        className="w-fit bg-main-01 dark:bg-[#efefff]/[.1] text-white py-[6px] Nunito-600 text-sm rounded-[12px] max-h-[32px]"
                        onClick={onSubmitReminderOrJoin}
                      >
                        {t("news.join")}
                      </Button>
                    )}
                  </>
                )}
              {event?.post?.joined && (
                <Button
                  variant="none"
                  className="px-0 text-sm bg-transparent text-main-01 Nunito-600 w-fit"
                  onClick={onNavigateToLinkEvent}
                >
                  {t("news.go_to_event")}&nbsp;&nbsp;
                  <IconDirection />
                </Button>
              )}
            </div>
          </div>

          <div className="relative col-span-12 cursor-pointer order-0 md:order-1 md:col-span-4">
            <Link
              href={navigateUrl()}
              onClick={() =>
                GAEventTracker("Click_event_banner", event?.post?.thumbnail)
              }
            >
              <Image
                src={getCdnImage(event?.post?.thumbnail ?? "", 100)}
                width={410}
                height={230}
                loading="lazy"
                className="w-full rounded-[16px] object-cover aspect-[16/9]"
                title={event?.post?.title}
                alt={event?.post?.title}
              />
            </Link>

            {!!getTypeEvent(event?.post?.start_time, event?.post?.end_time) && (
              <span
                className="absolute top-1 right-1 sd:top-2 sd:right-2 bg-[#faf8ff]/[.4] rounded-lg px-2 py-1 text-xs sd:text-sm text-white"
                style={{ backdropFilter: "blur(1px)" }}
              >
                {getTypeEvent(event?.post?.start_time, event?.post?.end_time)}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
