import { useQuery } from "@tanstack/react-query"
import { UserService } from "../../services"
import { useRouter } from "next/router"
import { IFacebookLoginParams } from "@/app/interfaces/IUser"
import { isClient, QUERY_KEY } from "@/app/configs"

const getUrlFacebookFn = () => {
  return UserService.getUrlFacebook()
}
const loginFacebookFn = (params: IFacebookLoginParams) => {
  return UserService.loginByFacebook(params)
}

export const useQueryLoginFacebook = (params: IFacebookLoginParams) => {
  return useQuery({
    queryKey: [QUERY_KEY.LOGIN_FACEBOOK, params],
    queryFn: () => loginFacebookFn(params),
    enabled: !!params,
    refetchOnWindowFocus: false,
    retry: 0
  })
}

export const useQueryGetUrlLoginFacebook = () => {
  const router = useRouter()
  const token = isClient ? window.localStorage.getItem("token") : ""
  return useQuery({
    queryKey: [QUERY_KEY.GET_URL_LOGIN_FACEBOOK],
    queryFn: () => getUrlFacebookFn(),
    enabled: !token,
    refetchOnWindowFocus: false

    // Sử dụng khi muốn transform data
    // select(data) {
    //   if (!!data?.data?._url) {
    //     localStorage.setItem("urlBeforeLogin", router.asPath)
    //     router.push(data?.data?._url)
    //   }
    // }
  })
}
