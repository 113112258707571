import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger
} from "@/app/components/ui/tabs"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import React, { useMemo } from "react"
import {
  PartnerAnalytic,
  PartnerEvent,
  PartnerPost,
  PartnerSetting
} from "./tab"
import { ParterTransactionManager } from "./tab/transaction-manager"
import useGAEventsTracker from "@/app/hooks/useGAEventsTracker"

export enum ENUM_PARTNER_TAB {
  PARTNER_POST = "partner-post",
  PARTNER_EVENT = "partner-event",
  PARTNER_ANALYTIC = "partner-analytic",
  PARTNER_SETTING = "partner-setting",
  TRANSACTION_MANAGER = "transaction-manager"
}

export const PartnerTab = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const activeTab = useMemo(() => router.query.tab, [router])
  const GAEventsTracker = useGAEventsTracker("Profile")

  const handleChangeTab = (value: ENUM_PARTNER_TAB) => {
    if (value === ENUM_PARTNER_TAB.PARTNER_SETTING)
      GAEventsTracker("Profile_setting")
    if (value === ENUM_PARTNER_TAB.PARTNER_ANALYTIC)
      GAEventsTracker("Profile_data")

    router.push({
      pathname: router.pathname,
      query: {
        id: router?.query?.id,
        tab: value
      }
    })
  }

  return (
    <div className="w-full">
      <Tabs
        value={
          ["undefined", ENUM_PARTNER_TAB.PARTNER_POST].includes(
            String(activeTab)
          )
            ? ENUM_PARTNER_TAB.PARTNER_POST
            : String(activeTab)
        }
        className="relative w-full"
        style={{
          zIndex: 2
        }}
      >
        <div className="max-w-full overflow-auto">
          <TabsList className="flex px-0 border-0 w-fit">
            <TabsTrigger
              value={ENUM_PARTNER_TAB.PARTNER_POST}
              className="flex items-center gap-[10px] text-neutral-05 dark:text-main-01 dark:data-[state=active]:text-main-01 dark:data-[state=active]:border-main-01 dark:data-[state=inactive]:text-neutral-05 w-fit"
              onClick={() => handleChangeTab(ENUM_PARTNER_TAB.PARTNER_POST)}
            >
              <span>{t("profile.tab_partner_post")}</span>
            </TabsTrigger>
            {/* <TabsTrigger
              value={ENUM_PARTNER_TAB.PARTNER_EVENT}
              className="flex items-center gap-[10px] text-neutral-05 dark:text-main-01 dark:data-[state=active]:text-main-01 dark:data-[state=active]:border-main-01 dark:data-[state=inactive]:text-neutral-05 w-fit"
              onClick={() => handleChangeTab(ENUM_PARTNER_TAB.PARTNER_EVENT)}
            >
              <span>{t("profile.tab_partner_event")}</span>
            </TabsTrigger> */}
            <TabsTrigger
              value={ENUM_PARTNER_TAB.PARTNER_ANALYTIC}
              className="flex items-center gap-[10px] text-neutral-05 dark:text-main-01 dark:data-[state=active]:text-main-01 dark:data-[state=active]:border-main-01 dark:data-[state=inactive]:text-neutral-05 w-fit"
              onClick={() => handleChangeTab(ENUM_PARTNER_TAB.PARTNER_ANALYTIC)}
            >
              <span>{t("profile.tab_partner_data")}</span>
            </TabsTrigger>
            <TabsTrigger
              value={ENUM_PARTNER_TAB.TRANSACTION_MANAGER}
              className="flex items-center gap-[10px] text-neutral-05 dark:text-main-01 dark:data-[state=active]:text-main-01 dark:data-[state=active]:border-main-01 dark:data-[state=inactive]:text-neutral-05 w-fit"
              onClick={() =>
                handleChangeTab(ENUM_PARTNER_TAB.TRANSACTION_MANAGER)
              }
            >
              <span>{t("profile.tab_transaction")}</span>
            </TabsTrigger>
            <TabsTrigger
              value={ENUM_PARTNER_TAB.PARTNER_SETTING}
              className="flex items-center gap-[10px] text-neutral-05 dark:text-main-01 dark:data-[state=active]:text-main-01 dark:data-[state=active]:border-main-01 dark:data-[state=inactive]:text-neutral-05 w-fit"
              onClick={() => handleChangeTab(ENUM_PARTNER_TAB.PARTNER_SETTING)}
            >
              <span>{t("profile.tab_setting")}</span>
            </TabsTrigger>
          </TabsList>
        </div>

        <div className="mt-0 border-t-[1px] border-[#000]/[.1] w-full dark:border-[#706E7E]">
          <TabsContent
            value={ENUM_PARTNER_TAB.PARTNER_POST}
            className="lg:[927px] 2xl:w-[1075px]"
          >
            <PartnerPost />
          </TabsContent>
          {/* <TabsContent
            value={ENUM_PARTNER_TAB.PARTNER_EVENT}
            className="lg:[927px] 2xl:w-[1075px]"
          >
            <PartnerEvent />
          </TabsContent> */}
          <TabsContent
            value={ENUM_PARTNER_TAB.PARTNER_ANALYTIC}
            className="lg:[927px] 2xl:w-[1075px]"
          >
            <PartnerAnalytic />
          </TabsContent>
          <TabsContent
            value={ENUM_PARTNER_TAB.TRANSACTION_MANAGER}
            className="w-full lg:[927px] 2xl:w-[1075px]"
          >
            <ParterTransactionManager />
          </TabsContent>
          <TabsContent
            value={ENUM_PARTNER_TAB.PARTNER_SETTING}
            className="lg:[927px] 2xl:w-[1075px]"
          >
            <PartnerSetting />
          </TabsContent>
        </div>
      </Tabs>
    </div>
  )
}
