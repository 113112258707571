import { DurationInputArg1, DurationInputArg2 } from "moment/moment"
import moment from "moment"
import "moment/locale/vi"
import { i18n } from "next-i18next"
import { Parser } from "htmlparser2"
import {
  DATE_FORMAT_2,
  DATE_FORMAT_2_REVERT,
  LIST_FILE_TYPE_IMAGE
} from "../configs"

const CONTENT_LENGTH = 70

export const removeAttributes = (element: any) => {
  const attributesToRemove = []

  for (const attribute of element.attributes) {
    attributesToRemove.push(attribute.name)
  }

  attributesToRemove.forEach((attributeName) => {
    element.removeAttribute(attributeName)
  })
  return element
}

export const removeStyle = (
  htmlString: string,
  showAll?: boolean,
  length?: number
) => {
  if (typeof window !== "undefined") {
    const div = document.createElement("div")
    div.innerHTML = htmlString
    const _length = length || CONTENT_LENGTH
    const elements = div.getElementsByTagName("*")
    for (let i = 0; i < elements.length; i++) {
      elements[i].removeAttribute("style")
      elements[i].removeAttribute("data-sheets-value")
      elements[i].removeAttribute("data-sheets-userformat")
      elements[i].removeAttribute("data-sheets-textstyleruns")
    }
    const links = div.getElementsByTagName("a")
    for (let i = 0; i < links.length; i++) {
      links[i].setAttribute("style", `color: #7673E5;`)
    }
    const uls = div.getElementsByTagName("ul")
    for (let i = 0; i < uls.length; i++) {
      uls[i].setAttribute(
        "style",
        "list-style: disc !important;padding-left: 20px; margin-left: 0;"
      )
    }

    const ols = div.getElementsByTagName("ol")
    for (let i = 0; i < ols.length; i++) {
      ols[i].setAttribute(
        "style",
        "list-style: auto !important; padding-left: 20px; margin-left: 0;"
      )
    }

    const words = div.innerHTML.split(" ")
    if (words.length > _length && !showAll) {
      return words.slice(0, _length).join(" ") + "<span>...</span>"
    } else {
      return div.innerHTML
    }
  }
}

export const modifiedStyleLinkDecoration = (html: string) => {
  if (typeof window !== "undefined") {
    const tempDiv = document.createElement("div")
    tempDiv.innerHTML = html

    const links = tempDiv.getElementsByTagName("a")

    for (let i = 0; i < links.length; i++) {
      const link = links[i]
      link.removeAttribute("style")
      link.style.textDecoration = "underline"
      link.style.color = "#B8631D"
    }
    return tempDiv.innerHTML
  }
}

export const removeInlineStylesAndClasses = (htmlContent: any) => {
  if (typeof window !== "undefined") {
    const div = document.createElement("div")
    div.innerHTML = htmlContent

    const allElements = div.querySelectorAll("*")
    allElements.forEach((element: any) => {
      removeAttributes(element)
    })

    // xóa các element trống của string
    const elementsToRemove: any = []

    // Lặp qua tất cả các thẻ và kiểm tra nếu chúng trống
    div.querySelectorAll("*").forEach((element: any) => {
      if (
        element.textContent.trim() === "" &&
        element.querySelectorAll("img").length === 0 &&
        !element.getAttribute("src")
      ) {
        elementsToRemove.push(element)
      }
    })

    // Xóa các thẻ trống
    elementsToRemove.forEach((element: any) => {
      element.parentNode.removeChild(element)
    })

    const modifiedHtml = div.outerHTML
    return modifiedHtml
  }
}

export const formatNumber = (_num: string | number) => {
  const num = Number(_num)
  const absNum = Math.abs(num)

  if (absNum >= 1e12) {
    return (num / 1e12).toFixed(1) + "t"
  } else if (absNum >= 1e9) {
    return (num / 1e9).toFixed(1) + "b"
  } else if (absNum >= 1e6) {
    return (num / 1e6).toFixed(1) + "m"
  } else if (absNum >= 1e3) {
    return (num / 1e3).toFixed(1) + "k"
  } else {
    return num.toString()
  }
}
export const dateTimeFormatter = (
  value: number | string,
  inputFormat: string | null = null,
  outputFormat = "DD/MM/YYYY HH:mm"
) => {
  if (!inputFormat) {
    return moment(value).format(outputFormat)
  } else {
    if (moment(value, inputFormat).isValid()) {
      return moment(value, inputFormat).format(outputFormat)
    } else {
      return value
    }
  }
}

export const timeAgoFormatter = (
  value: number | string,
  timeAgoAmount: DurationInputArg1 = 30,
  timeAgoType: DurationInputArg2 = "days",
  inputFormat: string | null = null
) => {
  moment.updateLocale("vi", {
    relativeTime: {
      future: "trong %s",
      past: "%s trước",
      s: "vài giây",
      ss: "%d giây", // Sử dụng số
      m: "1 phút", // Sử dụng số thay vì chữ
      mm: "%d phút", // Sử dụng số
      h: "1 giờ", // Sử dụng số thay vì chữ
      hh: "%d giờ", // Sử dụng số
      d: "1 ngày", // Sử dụng số
      dd: "%d ngày", // Sử dụng số
      M: "1 tháng", // Sử dụng số
      MM: "%d tháng", // Sử dụng số
      y: "1 năm", // Sử dụng số
      yy: "%d năm" // Sử dụng số
    }
  })

  moment.updateLocale("en", {
    relativeTime: {
      future: "in %s",
      past: "%s ago",
      s: "a few seconds",
      ss: "%d seconds", // Luôn hiển thị số
      m: "1 minute", // Luôn hiển thị số
      mm: "%d minutes", // Luôn hiển thị số
      h: "1 hour", // Luôn hiển thị số
      hh: "%d hours", // Luôn hiển thị số
      d: "1 day", // Luôn hiển thị số
      dd: "%d days", // Luôn hiển thị số
      M: "1 month", // Luôn hiển thị số
      MM: "%d months", // Luôn hiển thị số
      y: "1 year", // Luôn hiển thị số
      yy: "%d years" // Luôn hiển thị số
    }
  })

  const begin = moment().subtract(timeAgoAmount, timeAgoType).startOf("day")
  if (moment(value).isBefore(begin)) {
    return dateTimeFormatter(value, inputFormat)
  }
  if (!inputFormat) {
    return moment(value)
      .locale(i18n?.language || "en")
      .fromNow()
  } else {
    if (moment(value, inputFormat).isValid()) {
      return moment(value, inputFormat)
        .locale(i18n?.language || "en")
        .fromNow()
    } else {
      return value
    }
  }
}

export const timeFormatter = (
  value: number | string,
  outputFormat = DATE_FORMAT_2
) => {
  const currentTimestamp = moment()
  const oneDayAgo = moment().subtract(1, "days")
  const temp = moment(value).isBetween(oneDayAgo, currentTimestamp, null, "[]")

  if (!!temp) {
    return timeAgoFormatter(value)
  } else return dateTimeFormatter(value, null, outputFormat)
}

export const extractText = (htmlString: string) => {
  let modifiedHtml = ""
  const parser = new Parser({
    onopentag: (name) => {
      if (name === "br" || name === "p") {
        modifiedHtml += " "
      }
    },
    ontext: (text) => {
      // Append text content
      modifiedHtml += text
    }
  })

  parser.write(htmlString)
  parser.end()

  return modifiedHtml?.trim()
}

export const getBase64 = (
  file: any,
  cb: any,
  toFile?: boolean,
  type?: string
) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()

    if (file.type.match("image") || type === "image") {
      reader.readAsDataURL(file)
      reader.onload = async function () {
        await cb(reader.result)
        if (!toFile) {
          resolve(reader.result?.toString())
        } else {
        }
      }
    } else {
      reader.onload = function () {
        var blob = new Blob([reader.result as any], {
          type: file.type || "video/mp4"
        })
        var url = URL.createObjectURL(blob)

        var video = document.createElement("video")
        var timeupdate = function () {
          if (snapImage()) {
            video.removeEventListener("timeupdate", timeupdate)
            video.pause()
          }
        }
        video.addEventListener("loadeddata", function () {
          if (snapImage()) {
            video.removeEventListener("timeupdate", timeupdate)
          }
        })
        var snapImage = function () {
          var canvas: any = document.createElement("canvas")
          canvas.width = video.videoWidth
          canvas.height = video.videoHeight
          canvas
            .getContext("2d")
            .drawImage(video, 0, 0, canvas.width, canvas.height)

          var image = canvas.toDataURL()

          var success = image.length > 100000
          if (success && !toFile) {
            URL.revokeObjectURL(url)
            cb(reader.result)
            resolve(image)
          }
          if (toFile) {
            canvas.toBlob((blob: any) => {
              if (!blob) {
                console.error("Canvas is empty")
                return
              }
              resolve(blob)
            }, "image/jpeg")
          }

          return success
        }
        video.addEventListener("timeupdate", timeupdate)
        video.preload = "metadata"
        video.src = url
        // Load video in Safari / IE11
        video.muted = true
        video.playsInline = true
        video.play()
      }
      reader.readAsArrayBuffer(file)
    }

    reader.onerror = function (error) {
      reject(error)
    }
  })
}

export const checkImage = (file: any) => {
  const _list =
    typeof file.file === "string"
      ? file.file?.split("-")
      : file?.file?.name?.split(".")
  if (typeof file === "string") {
    const type = _list?.includes("image") ? "image" : "video"
    return type
  }
  const type = LIST_FILE_TYPE_IMAGE.includes(_list?.[_list.length - 1])
    ? "image"
    : "video"
  return type
}

export const removeVietnameseTones = (str: string) => {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a")
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e")
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i")
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o")
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u")
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y")
  str = str.replace(/đ/g, "d")
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A")
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E")
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I")
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O")
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U")
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y")
  str = str.replace(/Đ/g, "D")
  // Some system encode vietnamese combining accent as individual utf-8 characters
  // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, "") // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, "") // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
  // Remove extra spaces
  // Bỏ các khoảng trắng liền nhau
  str = str.replace(/ + /g, " ")
  str = str.trim()
  // Remove punctuations
  // Bỏ dấu câu, kí tự đặc biệt
  str = str.replace(
    /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
    " "
  )
  return str
}

export const getTimeBySubtract = (
  subtract:
    | "1 week"
    | "1 month"
    | "6 months"
    | "1 year"
    | "7 days"
    | "30 days"
    | "45 days"
    | "60 days"
    | "90 days"
): Record<string, string> => {
  const endDate = moment(
    moment().format(DATE_FORMAT_2_REVERT),
    DATE_FORMAT_2_REVERT
  )
  let startDate = null
  switch (subtract) {
    case "7 days": {
      startDate = endDate.subtract(7, "days").format(DATE_FORMAT_2_REVERT)
      break
    }
    case "30 days": {
      startDate = endDate.subtract(30, "days").format(DATE_FORMAT_2_REVERT)
      break
    }
    case "45 days": {
      startDate = endDate.subtract(45, "days").format(DATE_FORMAT_2_REVERT)
      break
    }
    case "60 days": {
      startDate = endDate.subtract(60, "days").format(DATE_FORMAT_2_REVERT)
      break
    }
    case "90 days": {
      startDate = endDate.subtract(90, "days").format(DATE_FORMAT_2_REVERT)
      break
    }

    case "1 month": {
      startDate = endDate.subtract(1, "months").format(DATE_FORMAT_2_REVERT)
      break
    }
    case "6 months": {
      startDate = endDate.subtract(6, "months").format(DATE_FORMAT_2_REVERT)
      break
    }
    case "1 year": {
      startDate = endDate.subtract(1, "years").format(DATE_FORMAT_2_REVERT)
      break
    }
    default: {
      startDate = endDate.subtract(1, "weeks").format(DATE_FORMAT_2_REVERT)
      break
    }
  }
  return {
    start_time: startDate,
    end_time: moment().format(DATE_FORMAT_2_REVERT)
  }
}

export const createSlugNameDetail: (title: string, id: string) => string = (
  title: string,
  id: string
) => {
  const slugTitle = !!title
    ? removeVietnameseTones(title)?.replaceAll(" ", "-")?.replaceAll("%", "-")
    : ""

  return `${slugTitle}-${id}`.toLowerCase()
}

export const getCdnImage = (url: string, percent?: number) => {
  const _cdnFilterLink = `https://cdn-thumb.playee.net/fast/filters:quality(${
    percent ? percent : 60
  }):format(webp)/`
  const _fullFilterLink = _cdnFilterLink + url.replace("https://", "")
  return _fullFilterLink
}

// export function convertYoutubeLink(str: string) {
//   const urls = str?.match(
//     /(https?:\/\/(?:www\.)?youtube\.com\/embed\/[a-zA-Z0-9_-]?([\w\-\.\/%&=]*))?/gi
//   )
//   const removeSameUrls = Array.from(new Set(urls))
//   const _list = removeSameUrls.filter((link: string) => {
//     return (
//       link.startsWith("https") ||
//       link.startsWith("http") ||
//       link.startsWith("ftp")
//     )
//   })

//   if (_list) {
//     _list.forEach(function (url) {
//       let replaceUrl =
//         '<iframe target="_blank" src="' +
//         url +
//         '" class="w-full h-[500px] mt-4">' +
//         url +
//         "</iframe>"
//       str = str
//     })
//   }

//   return str?.replace("(", "<br/>(")
// }

// export function convertLinkToHtml(str: string) {
//   const regex =
//     /((ftp|https?):\/\/)?(([\w\-]+\.)+(?!jpg|jpeg|png|gif)[\w]{2,})+([\w\-\.\/%&@?=]*)?/gi
//   let match
//   const links = []

//   while ((match = regex.exec(str)) !== null) {
//     if (
//       !/^https?:\/\/.*\.(jpg|jpeg|png|gif|svg|bmp|ico)$/i.test(match[0]) &&
//       !/^https?:\/\/.*\.(mp3|wav|ogg|aac)$/i.test(match[0]) &&
//       !/^https?:\/\/.*\.(mp4|avi|wmv|mov|flv|webm)$/i.test(match[0]) &&
//       str?.[match.index - 1] !== "" &&
//       (match[0].startsWith("https") ||
//         match[0].startsWith("http") ||
//         match[0].startsWith("ftp"))
//     ) {
//       links.push({
//         link: match[0],
//         index: match.index
//       })
//     }
//   }

//   let sum = 0

//   if (links) {
//     links.forEach(function (url, index) {
//       let replaceUrl =
//         '<a rel="nofollow" target="_blank" href="' +
//         url.link.replace("&nbsp", "") +
//         '" class="text-text-color-1">' +
//         url.link +
//         "</a>"
//       str =
//         str.substring(0, url.index + sum) +
//         replaceUrl +
//         str.substring(url.index + url.link.length + sum)
//       if (index !== url.link.length - 1) {
//         sum = sum + replaceUrl.length - url.link.length
//       }
//     })
//   }

//   return str
// }
