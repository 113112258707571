import interceptor from "@api/interceptor"
import IApiResponse from "@interfaces/IApiResponse"
import { AxiosResponse } from "axios"
import {
  IBodyTopup,
  IBodyTopupGlobal,
  IDoLaCharging,
  IMethodCard,
  ITopupGame,
  ITopupGameDetail,
  ITopupLaTransaction,
  ITopupPackage,
  ITopupServer,
  OnePackage
} from "@interfaces/ITopup"
import { IOrderParams } from "@interfaces/IStore"
import { $httpStore } from "./StoreServices"

interceptor($httpStore)
const resource = "/game/topup"

export default class TopupService {
  static getListGame(): Promise<AxiosResponse<IApiResponse<ITopupGame[]>>> {
    return $httpStore.get(`${resource}/listGame`)
  }

  static getLaTransaction(
    transaction_id: string
  ): Promise<AxiosResponse<IApiResponse<ITopupLaTransaction>>> {
    return $httpStore.get(`${resource}/laTransaction`, {
      params: { transaction_id }
    })
  }

  static checkPin(): Promise<AxiosResponse> {
    return $httpStore.get(`${resource}/laTransaction/checkPin`)
  }

  static doLaCharge(param: IDoLaCharging): Promise<
    AxiosResponse<
      IApiResponse<{
        transactionID: string
        enpoint: string
      }>
    >
  > {
    return $httpStore.post(`${resource}/laTransaction/doCharging`, param)
  }

  static getInfoGameDetail(
    slug_name: string
  ): Promise<AxiosResponse<IApiResponse<ITopupGameDetail>>> {
    return $httpStore.get(`${resource}/detailGame/${slug_name}`)
  }

  static getServerUserAndPlatformByUserId(params: {
    slug_name: string
    user_id: string
  }): Promise<AxiosResponse<IApiResponse<ITopupServer>>> {
    return $httpStore.get(`${resource}/searchServer/${params?.slug_name}`, {
      params
    })
  }
  static getSourcePackageAndTab(params: {
    slug_name: string
    user_id: string
    server_id: string
    role_id: string
    platform?: string
  }): Promise<AxiosResponse<IApiResponse<ITopupPackage>>> {
    return $httpStore.get(`${resource}/searchPackage/${params?.slug_name}`, {
      params
    })
  }

  static getSourcePackageAndTabInOpenGlobal(params: {
    slug_name: string
    country_code: string
  }): Promise<AxiosResponse<IApiResponse<ITopupPackage>>> {
    return $httpStore.get(`${resource}/xsollaPackage/${params?.slug_name}`, {
      params
    })
  }

  static getMethodByPackageId(params: {
    slug_name: string
    v3_id: string
    user_id: string
    server_id: string
    role_id: string
    platform?: string
  }): Promise<AxiosResponse<IApiResponse<OnePackage>>> {
    return $httpStore.get(`${resource}/searchMethod/${params.slug_name}`, {
      params
    })
  }
  static getRoleByServerAndUserId(params: {
    slug_name: string
    server_id: string
    user_id: string
    platform?: string
  }): Promise<
    AxiosResponse<
      IApiResponse<
        {
          role_id: string
          role_name: string
        }[]
      >
    >
  > {
    return $httpStore.get(`${resource}/searchRole/${params.slug_name}`, {
      params
    })
  }

  static submitDoTopup(body: IBodyTopup): Promise<
    AxiosResponse<
      IApiResponse<{
        transactionID: string
        url: string
      }>
    >
  > {
    const { slug_name, ...res } = body
    return $httpStore.post(`${resource}/doTopup/${slug_name}`, res)
  }

  static submitDoTopupGlobal(body: IBodyTopupGlobal): Promise<
    AxiosResponse<
      IApiResponse<{
        transactionID: string
        url: string
      }>
    >
  > {
    const { slug_name, ...res } = body
    return $httpStore.post(`${resource}/xsollaTopup/${slug_name}`, res)
  }

  static getTopupCallback(params: any): Promise<AxiosResponse<IApiResponse>> {
    return $httpStore.get(`${resource}/callback`, {
      params
    })
  }
  static getbyTransactionId(
    transaction_id: string
  ): Promise<AxiosResponse<IApiResponse>> {
    return $httpStore.get(`${resource}/vaTransaction`, {
      params: { transaction_id }
    })
  }

  static getStoreBag(params: {
    method: string
    amount: string
  }): Promise<AxiosResponse<IApiResponse<IMethodCard[]>>> {
    return $httpStore.get(`${resource}/storeBag`, {
      params
    })
  }

  static getPaymentHistory(
    params: IOrderParams
  ): Promise<AxiosResponse<IApiResponse<any>>> {
    return $httpStore.get(`${resource}/history`, {
      params: params
    })
  }
}
