import { Input } from "@/app/components/ui/input"
import { IGame } from "@/app/interfaces"
import React, { useContext, useState } from "react"
import Image from "next/image"
import { useFormContext } from "react-hook-form"
import { useTranslation } from "next-i18next"
import { AppContext } from "@/app/context"
import { useInfiniteGetListGame } from "@/app/services/query-fn/game"
import { Button } from "@/app/components/ui/button"
import { useDebounceEffect } from "@/app/hooks/useDebounceEffect"

const LIMIT_GAME = 10
const TIME_DEBOUNCE = 300
export const GameMention = () => {
  const { t } = useTranslation()
  const { setValue, watch } = useFormContext()
  const { themeApp } = useContext(AppContext)
  const [showInput, setShowInput] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>("")
  const [debounceSearch, setDebounceSearch] = useState<string>("")
  const { data: games, fetchNextPage } = useInfiniteGetListGame({
    category: "",
    type: "",
    keyword: debounceSearch,
    limit: 10,
    page: 1
  })

  useDebounceEffect(() => setDebounceSearch(searchValue), TIME_DEBOUNCE, [
    searchValue
  ])

  const selectedGames = watch("selectedGames", [])

  const handleSelectGame = (game: IGame) => {
    setValue("selectedGames", [game])

    setShowInput(false)
  }

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e?.target?.value)
  }
  const onRemoveSelectedMentionGame = (id: string) => {
    setValue(
      "selectedGames",
      selectedGames.filter((game: IGame) => game.id !== id)
    )
  }

  return (
    <div>
      <div className="flex items-center gap-3 cursor-pointer">
        <span className="text-bg-01 text-[18px] Nunito-700 dark:text-white">
          {t("news.mention_game")}
        </span>
        {showInput ? (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => setShowInput(false)}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M18 2H6C3.79086 2 2 3.79086 2 6V18C2 20.2091 3.79086 22 6 22H18C20.2091 22 22 20.2091 22 18V6C22 3.79086 20.2091 2 18 2ZM16 12.75C16.4142 12.75 16.75 12.4142 16.75 12C16.75 11.5858 16.4142 11.25 16 11.25H8C7.58579 11.25 7.25 11.5858 7.25 12C7.25 12.4142 7.58579 12.75 8 12.75H16Z"
              fill={themeApp === "dark" ? "#fff" : "#100D28"}
            />
          </svg>
        ) : (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => setShowInput(true)}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6 2H18C20.2091 2 22 3.79086 22 6V18C22 20.2091 20.2091 22 18 22H6C3.79086 22 2 20.2091 2 18V6C2 3.79086 3.79086 2 6 2ZM12 7.25C12.4142 7.25 12.75 7.58579 12.75 8V11.25H16C16.4142 11.25 16.75 11.5858 16.75 12C16.75 12.4142 16.4142 12.75 16 12.75H12.75V16C12.75 16.4142 12.4142 16.75 12 16.75C11.5858 16.75 11.25 16.4142 11.25 16V12.75H8C7.58579 12.75 7.25 12.4142 7.25 12C7.25 11.5858 7.58579 11.25 8 11.25H11.25V8C11.25 7.58579 11.5858 7.25 12 7.25Z"
              fill={themeApp === "dark" ? "#fff" : "#100D28"}
            />
          </svg>
        )}
      </div>
      {showInput ? (
        <>
          <div className="relative w-full">
            <Input
              autoFocus={showInput}
              placeholder={t("news.placeholder_mention_game")}
              value={searchValue}
              onChange={onChangeSearch}
              className="border-0 border-b-[1px] rounded-none h-10 dark:bg-transparent"
            />
            <div
              className="w-full absolute top-[57px] bg-white dark:bg-dark-mode-box h-[250px] overflow-auto p-3 rounded-tr-sm rounded-tl-sm rounded-bl-2xl rounded-br-2xl no-scrollbar z-10"
              style={{ boxShadow: "0px 3px 30px 0px rgba(0, 0, 0, 0.15)" }}
            >
              {React.Children.toArray(
                games?.pages?.flat()?.map((game: IGame) => {
                  return (
                    <div
                      className="w-full rounded-xl py-[6px] px-3 flex gap-4 items-center cursor-pointer bg-white dark:bg-transparent hover:bg-bg-disable dark:hover:bg-white/10"
                      onClick={() => handleSelectGame(game)}
                    >
                      <Image
                        src={game?.icon}
                        alt="game"
                        width={40}
                        height={40}
                        className="rounded-xl w-[40px] h-[40px] object-contain"
                      />
                      <div className="flex flex-col group">
                        <span className="text-sm Nunito-700 text-bg-01 dark:text-white group-hover:text-main-01 group-focus:text-main-01 dark:group-hover:text-main-white dark:group-focus:text-main-white">
                          {game.game_name}
                        </span>
                        <div className="flex items-center">
                          <div className="w-[12px] h-[12px]">
                            <svg
                              width="10"
                              height="10"
                              viewBox="0 0 12 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5.01641 1.63571C5.41875 0.788099 6.58125 0.788097 6.98359 1.6357L7.67895 3.10059C7.83872 3.43717 8.14755 3.67047 8.50481 3.72444L10.0597 3.95935C10.9593 4.09527 11.3186 5.24477 10.6676 5.90454L9.54246 7.0448C9.28394 7.30679 9.16598 7.68427 9.22701 8.05421L9.49261 9.66427C9.64629 10.5959 8.70581 11.3063 7.90112 10.8665L6.51041 10.1063C6.19087 9.93164 5.80913 9.93164 5.48959 10.1063L4.09888 10.8665C3.2942 11.3063 2.35371 10.5959 2.50739 9.66428L2.77299 8.05421C2.83402 7.68427 2.71606 7.30679 2.45754 7.0448L1.33244 5.90454C0.681433 5.24477 1.04066 4.09527 1.94033 3.95935L3.49519 3.72444C3.85244 3.67047 4.16128 3.43717 4.32105 3.10059L5.01641 1.63571Z"
                                fill="#7673E6"
                              />
                            </svg>
                          </div>
                          <span className="text-neutral text-[10px] Nunito-400 dark:text-white">
                            {game.rating.stars}
                          </span>
                          <span className="w-1 h-1 rounded-full bg-neutral mx-[6px]" />
                          <span className="text-neutral dark:text-neutral-08 text-[10px] Nunito-400">
                            {game.developer}
                          </span>
                        </div>
                      </div>
                    </div>
                  )
                })
              )}

              {Number(games?.pages?.at(-1)?.length) >= LIMIT_GAME && (
                <Button
                  variant="none"
                  className="px-[24px] text-[16px] rounded-[8px] Nunito-500 my-auto text-main-01 h-[30px]"
                  onClick={() => fetchNextPage()}
                >
                  {t("common.view_more")}
                </Button>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className="grid grid-cols-1 mt-3 ">
          {React.Children.toArray(
            Array.from(new Set(selectedGames)).map((game: any) => (
              <div className="max-w-full w-fit border-[1px] border-main-01 px-2 py-[6px] flex justify-between rounded-lg items-center gap-2">
                <div className="flex items-center gap-2">
                  <Image
                    src={game?.icon}
                    alt="game"
                    width={40}
                    height={40}
                    className="rounded-xl w-[40px] h-[40px] object-contain"
                  />
                  <span className="text-sm Nunito-700 text-main-01 line-clamp-1">
                    {game.game_name}
                  </span>
                </div>
                <div onClick={() => onRemoveSelectedMentionGame(game?.id)}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="cursor-pointer"
                  >
                    <path
                      d="M16.2431 7.75786L7.75781 16.2431M16.2431 16.2431L7.75781 7.75781"
                      stroke="#7673E6"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  )
}
