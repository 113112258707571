import { Button } from "@/app/components/ui/button"
import React, { useEffect, useMemo, useState } from "react"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@/app/components/ui/select"
import { useTranslation } from "next-i18next"
import { ItemDetailStatistical } from "./ItemDetailStatistical"
import {
  useQueryGetListDataEvent,
  useQueryGetListDataNews
} from "@/app/services/query-fn/user/user-analytic-query"
import { getTimeBySubtract } from "@/app/helpers/function"
import { DataEmpty } from "@/app/components/data-empty"
import { useInView } from "react-intersection-observer"

export const DetailStatistical = () => {
  const { t } = useTranslation()
  const { ref, inView } = useInView()

  const [tab, setTab] = useState<"news" | "event">("news")
  const [time, setTime] = useState<
    "7 days" | "30 days" | "45 days" | "60 days" | "90 days"
  >("7 days")

  const timeFilter = useMemo(() => {
    return getTimeBySubtract(time)
  }, [time])

  const FilterTime = [
    { label: t("profile.seven_days"), value: "7 days" },
    { label: t("profile._30_days"), value: "30 days" },
    { label: t("profile._45_days"), value: "45 days" },
    { label: t("profile._60_days"), value: "60 days" },
    { label: t("profile._90_days"), value: "90 days" }
  ]
  const { data: listNews, fetchNextPage: fetchNextPageNews } =
    useQueryGetListDataNews({
      ...timeFilter,
      type: tab
    })

  const onValueChange = (
    value: "7 days" | "30 days" | "45 days" | "60 days" | "90 days"
  ) => {
    setTime(value)
  }

  useEffect(() => {
    if (inView) {
      fetchNextPageNews()
    }
  }, [fetchNextPageNews, inView, tab])

  return (
    <div className="col-span-12">
      <div className="flex items-center justify-between pt-[8px] pb-[16px] border-b border-black/10 dark:border-neutral-03">
        <div className="flex w-full items-center gap-[24px] flex-col lg:flex-row">
          <p className="text-bg-01 Nunito-700 text-[18px] hidden lg:block dark:text-main-white">
            {t("profile.detail_stat_title")}
          </p>
        </div>
        <Select onValueChange={onValueChange}>
          <SelectTrigger className="w-full max-w-[130px] max-h-[28px] file:m-0 text-xs Nunito-400 text-main-01 dark:text-white dark:bg-white/10 dark:backdrop-blur-[20px] border-0 bg-bg-disable text-left">
            <SelectValue
              placeholder={t("profile.seven_days")}
              defaultValue={t("profile.seven_days")}
            />
          </SelectTrigger>
          <SelectContent className="p-1 rounded-[16px]" align="end">
            {FilterTime.map((item: { label: string; value: string }) => (
              <SelectItem
                value={item?.value}
                key={item?.value}
                className="py-3 px-4 rounded-[8px] hover:!bg-bg-disable hover:!text-main-01 text-base Nunito-600 focus:bg-bg-disable focus:text-main-01 dark:hover:!bg-[#F1F1FC]/[.1] dark:focus:!bg-[#F1F1FC]/[.1]"
              >
                {item?.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
      <div className="space-y-[20px] mt-[16px]">
        {!!listNews?.pages?.flat()?.length ? (
          listNews?.pages
            ?.flat()
            ?.map((item, index: number) => (
              <ItemDetailStatistical
                key={index}
                detail={item}
                activeTab={tab}
              />
            ))
        ) : (
          <div className="w-full flex items-center justify-center h-[200px]">
            <DataEmpty title={t("profile.no_data")} />
          </div>
        )}
      </div>
      <button ref={ref} className="opacity-0">
        Load more
      </button>
    </div>
  )
}
