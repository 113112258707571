/* eslint-disable react/jsx-key */
import { useNavigation } from "react-day-picker"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@/app/components/ui/select"
import { useContext, useState } from "react"
import { useTranslation } from "next-i18next"
import { END_YEAR, FROM_YEAR, months, vnMonth } from "@/app/configs"
import { AppContext } from "@/app/context"

export function CustomSelectMonthYear(props: any) {
  const { t } = useTranslation()
  const { userInfo } = useContext(AppContext)
  const { currentMonth, goToMonth } = useNavigation()
  const [date, setDate] = useState<{
    month: number
    year: number
  }>({
    month: Number(currentMonth.getMonth()),
    year: Number(currentMonth.getFullYear())
  })

  const listMonth = userInfo?.lang === "vi" ? vnMonth : months
  const listYear = Array.from(
    { length: END_YEAR - FROM_YEAR + 1 },
    (_, index) => FROM_YEAR + index
  )

  const onValueChange = (value: string, type: "month" | "year") => {
    if (type === "year") {
      const month = date.month
      const year = Number(value)
      setDate({ ...date, year })
      goToMonth(new Date(year, month))
    }
    if (type === "month") {
      const month = listMonth.indexOf(value)
      const year = date.year
      setDate({ ...date, month })
      goToMonth(new Date(year, month))
    }
  }

  return (
    <div className="grid grid-cols-2 gap-2">
      <div>
        <Select
          onValueChange={(value: string) => onValueChange(value, "month")}
        >
          <SelectTrigger className="w-full border-0 m-0 md:m-2 Nunito-700 text-base text-main-01 placeholder:text-main-01  dark:text-white dark:bg-white/10 dark:backdrop-blur-[20px]">
            <SelectValue
              placeholder={listMonth[Number(currentMonth.getMonth())]}
            />
          </SelectTrigger>
          <SelectContent className="p-1 rounded-[16px] max-h-[250px]">
            {listMonth.map((item: string) => (
              <SelectItem
                value={item}
                key={String(item)}
                className="py-[8px] px-[16px] rounded-[12px] hover:!bg-bg-disable hover:!text-main-01 text-base Nunito-600 focus:bg-bg-disable focus:text-main-01 dark:hover:!bg-[#F1F1FC]/[.1] dark:focus:!bg-[#F1F1FC]/[.1] text-center flex items-center justify-center cursor-pointer"
              >
                {item}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      <div>
        <Select onValueChange={(value: string) => onValueChange(value, "year")}>
          <SelectTrigger className="w-full border-0 m-0 md:m-2 Nunito-700 text-base text-main-01 placeholder:text-main-01  dark:text-white dark:bg-white/10 dark:backdrop-blur-[20px]">
            <SelectValue
              placeholder={Number(currentMonth.getFullYear())}
              className="text-base Nunito-700 text-main-01 placeholder:text-main-01"
            />
          </SelectTrigger>
          <SelectContent className="p-1 rounded-[16px] rounded-tl-none rounded-tr-none max-h-[250px]">
            {listYear.map((item: number) => (
              <SelectItem
                key={String(item)}
                value={String(item)}
                className="py-[8px] px-[16px] rounded-[12px] hover:!bg-bg-disable hover:!text-main-01 text-base Nunito-600 focus:bg-bg-disable focus:text-main-01 dark:hover:!bg-[#F1F1FC]/[.1] dark:focus:!bg-[#F1F1FC]/[.1] text-center flex items-center justify-center cursor-pointer"
              >
                {item}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    </div>
  )
}
