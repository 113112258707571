import {
  IGiftcodeParams,
  IRoleGiftcodeParams,
  IServerGiftcodeParams
} from "@/app/interfaces/IGiftcode"
import { GiftcodeServices } from "../../services/Giftcode"
import { useQuery } from "@tanstack/react-query"
import { QUERY_KEY, isClient } from "@/app/configs"
import { removeNullKeyObject } from "@/app/lib/utils"
import { useToast } from "@/app/components/ui/use-toast"

const getListGiftcode = async (params: IGiftcodeParams) => {
  const data = await GiftcodeServices.getAllGiftcode(params)

  return data.data.data
}

const getListGameGiftcode = async () => {
  const data = await GiftcodeServices.getGameGiftcode()
  return data.data.data
}

const getListServerGiftcode = async (body: IServerGiftcodeParams) => {
  const data = await GiftcodeServices.getServerGiftcode(body)
  return data.data
}

const getListRoleGiftcode = async (body: IRoleGiftcodeParams) => {
  const data = await GiftcodeServices.getRoleGiftcode(body)
  return data.data
}

const getGiftcodeDetail = async ({ event_id }: { event_id: string }) => {
  const data = await GiftcodeServices.getGiftcodeDetail({ event_id })
  return data.data.data
}

const getGiftcodeInfo = async ({ event_id }: { event_id: string }) => {
  const data = await GiftcodeServices.getGiftcodeInfo({ event_id })
  return data.data.data
}

export const useQueryGetListGiftcode = (params: IGiftcodeParams) => {
  const _params = removeNullKeyObject(params)
  return useQuery({
    queryKey: [QUERY_KEY.LIST_GIFTCODE, _params],
    queryFn: () => getListGiftcode(_params),
    enabled: !!_params,
    refetchOnWindowFocus: false,
    retry: 0
  })
}

export const useQueryGetGiftcodeDetail = ({
  event_id
}: {
  event_id: string
}) => {
  return useQuery({
    queryKey: [QUERY_KEY.GIFTCODE_DETAIL, { event_id }],
    queryFn: () => getGiftcodeDetail({ event_id }),
    enabled: !!event_id,
    refetchOnWindowFocus: false,
    retry: 0
  })
}

export const useQueryGetGiftcodeInfo = ({ event_id }: { event_id: string }) => {
  return useQuery({
    queryKey: [QUERY_KEY.GIFTCODE_INFO, { event_id }],
    queryFn: () => getGiftcodeInfo({ event_id }),
    enabled: !!event_id,
    refetchOnWindowFocus: false,
    retry: 0
  })
}

export const useQueryGetListGameGiftcode = () => {
  const token = isClient ? window.localStorage.getItem("token") : null
  return useQuery({
    queryKey: [QUERY_KEY.LIST_GAME_GIFTCODE],
    queryFn: () => getListGameGiftcode(),
    // enabled: !!token,
    refetchOnWindowFocus: false,
    retry: 0
  })
}

export const useQueryGetListServerGiftcode = (
  params: IServerGiftcodeParams
) => {
  return useQuery({
    queryKey: [QUERY_KEY.LIST_SERVER_GIFTCODE, params],
    enabled: !!params?.game_id && !!params?.user_id,
    queryFn: () => getListServerGiftcode(params),
    refetchOnWindowFocus: false,
    retry: 0
  })
}

export const useQueryGetListRoleGiftcode = (params: IRoleGiftcodeParams) => {
  return useQuery({
    queryKey: [QUERY_KEY.LIST_ROLE_GIFTCODE, params],
    enabled: !!params?.server_id && !!params?.user_id,
    queryFn: () => getListRoleGiftcode(params),
    refetchOnWindowFocus: false,
    retry: 0
  })
}
