import { useInfiniteQuery, useQuery } from "@tanstack/react-query"
import { CreateType, QUERY_KEY } from "@/app/configs"
import { NewsService } from "../../services"
import { INewsByFilter, IParamEventByType } from "@/app/interfaces"

const getNewsFn = async (pageParam: number) => {
  const data = await NewsService.getAllNews(pageParam)
  return data.data.data
}

const getListStoreNewsFn = async (
  pageParam: number,
  keyword: string,
  sort: number
) => {
  const data = await NewsService.getListStoreNews(pageParam, keyword, sort)
  return data.data.data
}

const getLatestNewsFn = async (params: IParamEventByType) => {
  const data = await NewsService.getLatestNews(params)
  return data.data.data
}

const checkDraftFn = async () => {
  const res = await NewsService.checkDraft()
  return res?.data?.data
}

const getNewsByTypeFn = async (type: string, limit: number) => {
  const data = await NewsService.getNewsByType({ type, limit })
  return data?.data?.data
}

const getRelatedNews = async (params: {
  pageParam: number
  id: string
  limit: number
}) => {
  const res = await NewsService.relatedNews(params)
  return res?.data?.data
}

const getRelatedNewsByGame = async (params: {
  pageParam: number
  game_id: string
  limit: number
}) => {
  const res = await NewsService.relatedNewsByGame(params)
  return res?.data?.data
}

const getAllNewsByUser = async (pageParam: number, sort: number) => {
  const res = await NewsService.getAllNewsByUser(pageParam, sort)
  return res?.data?.data
}

const getAllNewsByOtherUser = async (
  pageParam: number,
  sort: number,
  other_userid: string
) => {
  const res = await NewsService.getAllNewsByOtherUser(
    pageParam,
    sort,
    other_userid
  )
  return res?.data?.data
}

const getMyNewsByFilter = async (param: INewsByFilter, pageParam: number) => {
  const res = await NewsService.getMyNewsByFilter(param, pageParam)
  return res?.data?.data
}

const getOtherUserNewsByFilter = async (
  param: INewsByFilter & { other_userid: string },
  pageParam: number
) => {
  const res = await NewsService.getOtherUserNewsByFilter(param, pageParam)
  return res?.data?.data
}

const getListNewsByHashtag = async (keyword: string, pageParam: number) => {
  const res = await NewsService.getAllNewsByHashtag(keyword, pageParam)
  return res?.data?.data
}

export const useQueryGetNewsByHashtag = (keyword: string, enabled: boolean) => {
  return useInfiniteQuery({
    queryKey: [QUERY_KEY.LIST_NEWS_BY_HASHTAG, keyword],
    queryFn: ({ pageParam }) => getListNewsByHashtag(keyword, pageParam),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => {
      return lastPage?.length > 9 ? pages?.length + 1 : undefined
    },
    enabled: !!keyword && !!enabled,
    refetchOnMount: "always"
  })
}

export const useQueryGetMyNewsByFilter = (param: INewsByFilter) => {
  return useInfiniteQuery({
    queryKey: [QUERY_KEY.MY_NEWS_BY_FILTER, Object.values(param).join("-")],
    queryFn: ({ pageParam }) => getMyNewsByFilter(param, pageParam),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => {
      return lastPage?.length > 9 ? pages?.length + 1 : undefined
    },
    enabled: !!param?.keyword,
    refetchOnMount: "always"
  })
}

export const useQueryGetOtherUserNewsByFilter = (
  param: INewsByFilter & { other_userid: string }
) => {
  return useInfiniteQuery({
    queryKey: [
      QUERY_KEY.OTHER_USER_NEWS_BY_FILTER,
      Object.values(param).join("-")
    ],
    queryFn: ({ pageParam }) => getOtherUserNewsByFilter(param, pageParam),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => {
      return lastPage?.length > 9 ? pages?.length + 1 : undefined
    },
    enabled:
      (!!param?.keyword || !!param.end_date || !!param.start_date) &&
      param.other_userid !== "undefined",
    refetchOnMount: "always"
  })
}
export const useQueryGetListNewsByUser = (search: string, sort: number) => {
  return useInfiniteQuery({
    queryKey: [QUERY_KEY.LIST_NEWS_BY_USER, sort],
    queryFn: ({ pageParam }) => getAllNewsByUser(pageParam, sort),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => {
      return lastPage?.length > 9 ? pages?.length + 1 : undefined
    },
    enabled: !search,
    refetchOnMount: "always",
    staleTime: 0,
    refetchOnWindowFocus: "always",
    notifyOnChangeProps: ["data"]
  })
}

export const useQueryGetListNewsByOtherUserId = (
  search: string,
  sort: number,
  other_userid: string
) => {
  return useInfiniteQuery({
    queryKey: [QUERY_KEY.LIST_NEWS_BY_OTHER_USER, sort, other_userid],
    queryFn: ({ pageParam }) =>
      getAllNewsByOtherUser(pageParam, sort, other_userid),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => {
      return lastPage?.length > 9 ? pages?.length + 1 : undefined
    },
    enabled: !search && other_userid !== "undefined",
    refetchOnMount: "always"
  })
}

export const useQueryGetListNews = () => {
  return useInfiniteQuery({
    queryKey: [QUERY_KEY.LIST_NEWS],
    queryFn: ({ pageParam }) => getNewsFn(pageParam),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => {
      return lastPage?.length > 9 ? pages?.length + 1 : undefined
    }
  })
}

export const useQueryGetListStoredNews = (keyword: string, sort: number) => {
  return useInfiniteQuery({
    queryKey: [QUERY_KEY.LIST_STORED_NEWS, keyword, sort],
    queryFn: ({ pageParam }) => getListStoreNewsFn(pageParam, keyword, sort),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => {
      return lastPage?.length > 9 ? pages?.length + 1 : undefined
    },
    refetchOnMount: "always"
  })
}

export const useQueryGetListRelatedNews = ({
  id,
  limit,
  type
}: {
  id: string
  limit: number
  type: string
}) => {
  return useQuery({
    queryKey: [QUERY_KEY.LIST_RELATED_NEWS, id],
    queryFn: () => getRelatedNews({ pageParam: 1, id, limit }),
    enabled:
      !!id && id !== "undefined" && type !== "undefined" && type === "news"
  })
}

export const useQueryGetListRelatedNewsByGame = ({
  game_id,
  limit = 10
}: {
  game_id: string
  limit: number
}) => {
  return useInfiniteQuery({
    queryKey: [QUERY_KEY.LIST_RELATED_NEWS_BY_GAME, game_id],
    queryFn: ({ pageParam }) =>
      getRelatedNewsByGame({ pageParam: pageParam, game_id, limit }),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => {
      return lastPage?.length > limit - 1 ? pages?.length + 1 : undefined
    },
    enabled: !!game_id && game_id !== "undefined"
  })
}

export const useQueryCheckDraft = (type: string) => {
  return useQuery({
    queryKey: [QUERY_KEY.CHECK_DRAFT],
    queryFn: () => checkDraftFn(),
    enabled: !!type && type === String(CreateType.CREATE_POST)
  })
}

export const useQueryGetListHotNews = (type: string, limit: number) => {
  return useQuery({
    queryKey: [QUERY_KEY.LIST_HOT, type, limit],
    queryFn: () => getNewsByTypeFn(type, limit)
  })
}

export const useQueryGetListForYou = (type: string, limit: number) => {
  return useQuery({
    queryKey: [QUERY_KEY.NEWS_FOR_YOU, type, limit],
    queryFn: () => getNewsByTypeFn(type, limit)
  })
}

export const useQueryGetListNewsLatest = (
  params: Omit<IParamEventByType, "page">
) => {
  return useInfiniteQuery({
    queryKey: [QUERY_KEY.LIST_NEWS_LATEST, Object.values(params).join("_")],
    queryFn: ({ pageParam }) => getLatestNewsFn({ ...params, page: pageParam }),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => {
      return lastPage?.length > 9 ? pages?.length + 1 : undefined
    }
  })
}
