import { AppContext } from "@/app/context"
import { useTranslation } from "next-i18next"
import React, { useContext, useMemo, useState } from "react"
import { ModalFollower } from "../../partner/modal"
import { ModalFriend, ModalGameFollowing } from "../../normal-user/modal"
import {
  useQueryGetGameFollow,
  useQueryGetProfileById
} from "@/app/services/query-fn"
import { useRouter } from "next/router"

export const Overview = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const { userInfo } = useContext(AppContext)
  const userId = useMemo(() => router?.query?.id, [router])

  const { data: otherUser } = useQueryGetProfileById(
    userId !== userInfo?.userid && userId !== userInfo?.alias
      ? String(userId)
      : undefined
  )

  const dataUser =
    userId !== userInfo?.userid && userId !== userInfo?.alias && !!otherUser
      ? otherUser
      : userInfo

  const [showModalUser, setShowModalUser] = useState<boolean>(false)
  const [showModalPartner, setShowModalPartner] = useState<boolean>(false)
  const [showModalGameFollowing, setShowModalGameFollowing] =
    useState<boolean>(false)

  const { data } = useQueryGetGameFollow(!dataUser?.authentic)
  const formatNumber = (num: number) => {
    return Intl.NumberFormat("en-US", {
      notation: "compact",
      maximumFractionDigits: 1
    }).format(num)
  }

  const onNavigateToTab = (type: "post" | "event") => {
    router.push({
      pathname: router.pathname,
      query: {
        tab:
          userId !== userInfo?.userid && userId !== userInfo?.alias
            ? `other-partner-${type}`
            : `partner-${type}`,
        id: router?.query?.id
      }
    })
  }

  const contentPartner = () => {
    return (
      <div className="w-full border border-black/10 bg-bg-02-text-box py-[10px] px-4 flex items-center justify-around rounded-[16px] cursor-pointer max-w-[288px] m-auto dark:bg-[rgba(240,242,245,0.1)]">
        <div
          className="flex flex-col items-center justify-center"
          onClick={() => {
            if (userId === userInfo?.userid || userId === userInfo?.alias) {
              setShowModalPartner(true)
            }
          }}
        >
          <p className="Nunito-700 text-bg-01 text-[16px] dark:text-white">
            {formatNumber(Number(dataUser?.total_follow ?? 0))}
          </p>
          <p className="text-sm text-neutral-01 Nunito-500">
            {t("profile.overview_follower")}
          </p>
        </div>
        <div className="w-[1px] h-[32px] bg-black/10" />
        <div
          className="flex flex-col items-center justify-center"
          onClick={() => onNavigateToTab("post")}
        >
          <p className="Nunito-700 text-bg-01 text-[16px] dark:text-white">
            {formatNumber(Number(dataUser?.total_news ?? 0))}
          </p>
          <p className="text-sm text-neutral-01 Nunito-500">
            {t("common.post")}
          </p>
        </div>
        {/* <div className="w-[1px] h-[32px] bg-black/10" />
        <div
          className="flex flex-col items-center justify-center "
          onClick={() => onNavigateToTab("event")}
        >
          <p className="Nunito-700 text-bg-01 text-[16px] dark:text-white">
            {formatNumber(Number(dataUser?.total_event ?? 0))}
          </p>
          <p className="text-sm text-neutral-01 Nunito-500">
            {t("profile.tab_partner_event")}
          </p>
        </div> */}
      </div>
    )
  }
  const contentNormalUser = () => {
    return (
      <div className="w-full border border-black/10 bg-bg-02-text-box py-[10px] px-4 flex items-center justify-around rounded-[16px] dark:bg-[rgba(240,242,245,0.1)]">
        <div
          className="flex flex-col items-center justify-center cursor-pointer"
          onClick={() => {
            if (userId === userInfo?.userid || userId === userInfo?.alias) {
              setShowModalUser(true)
            }
          }}
        >
          <p className="Nunito-700 text-bg-01 text-[16px] dark:text-white">
            {formatNumber(Number(dataUser?.total_friend ?? 0))}
          </p>
          <p className="text-sm text-neutral-01 Nunito-500">
            {t("profile.friend")}
          </p>
        </div>
        <div className="w-[1px] h-[32px] bg-black/10" />
        <div
          className="flex flex-col items-center justify-center cursor-pointer"
          onClick={() => {
            if (userId === userInfo?.userid || userId === userInfo?.alias) {
              setShowModalGameFollowing(true)
            }
          }}
        >
          <p className="Nunito-700 text-bg-01 text-[16px] dark:text-white">
            {formatNumber(Number(data?.totalFollow ?? 0))}
          </p>
          <p className="text-sm text-neutral-01 Nunito-500">Games</p>
        </div>
      </div>
    )
  }
  return (
    <div className="w-full mt-[16px] lg:mt-[48px]">
      {dataUser?.authentic ? contentPartner() : contentNormalUser()}
      <ModalFollower
        open={showModalPartner}
        onClose={() => setShowModalPartner(false)}
      />
      <ModalFriend
        open={showModalUser}
        onClose={() => setShowModalUser(false)}
      />
      <ModalGameFollowing
        open={showModalGameFollowing}
        onClose={() => setShowModalGameFollowing(false)}
      />
    </div>
  )
}
