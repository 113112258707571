import { AppContext } from "@/app/context"
import { CreateType, OPEN_MODAL, isClient } from "@/app/configs"
import React, { FC, useCallback, useContext, useEffect, useState } from "react"
import { Button } from "../ui/button"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import { IconSearch } from "../icon-tsx/IconSearch"
import { SelectTheme } from "./SelectTheme"
import NotificationHeader from "./NotificationHeader"
import AccountHeader from "./AccountHeader"
import { IconCreatePost } from "../icon-tsx/IconCreatePost"
import { MenuBar } from "./MenuBar"
import Logo from "@/public/img/playee_full_text.png"
import LogoDark from "@/public/img/playee_full_text_dark.png"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from "../ui/dropdown-menu"
import Image from "next/image"

interface HeaderProps {
  expandHeader?: boolean
}

export const Header: FC<HeaderProps> = ({ expandHeader }) => {
  const { t } = useTranslation()
  const router = useRouter()
  const { themeApp, setOpenModal } = useContext(AppContext)
  const [isLogin, setIsLogin] = useState(false)
  const token = isClient ? window.localStorage.getItem("token") : null
  const [expand, setExpand] = useState(false)
  const [showCreate, setShowCreate] = useState<boolean>(false)
  const [hoverIcon, setHoverIcon] = useState<boolean>(false)

  useEffect(() => {
    if (!!token) {
      setIsLogin(true)
    } else {
      setIsLogin(false)
    }
  }, [token])

  const { userInfo } = useContext(AppContext)

  const changeBackground = () => {
    if (window.scrollY >= 200) {
      setExpand(true)
    } else {
      setExpand(false)
    }
  }

  const onNavigateCreate = useCallback(
    (type?: CreateType) => {
      router.push(`/create-post?type=${type}`)
    },
    [router]
  )

  useEffect(() => {
    if (expandHeader) {
      changeBackground()
      // adding the event when scroll change Logo
      window.addEventListener("scroll", changeBackground)
    }

    return () => {
      window.removeEventListener("scroll", changeBackground)
    }
  }, [expandHeader])

  return (
    <div
      className={`w-full fixed top-0 z-[40] md:h-[84px] sm:h-[72px] h-[60px] transition duration-500 ${
        expandHeader ? (!expand ? "-translate-y-[84px]" : "translate-y-0") : ""
      }`}
      style={{
        boxShadow: "4px 4px 15px 0px rgba(0, 0, 0, 0.04)",
        background: "var(--bg-header)"
      }}
    >
      <header className="sm:px-[32px] px-[16px] flex items-center justify-between h-full xs:pr-[24px]">
        <div
          id="header-left-side"
          className="flex items-center md:max-w-[150px] lg:max-w-[372px] w-full justify-between"
        >
          <Image
            width={142}
            height={49}
            src={themeApp === "dark" ? LogoDark.src : Logo.src}
            className="lg:w-[142px] lg:h-[49px] md:w-[100px] md:h-[34px] w-[105px] h-[38px] cursor-pointer"
            alt="logo"
            onClick={() => router.push("/")}
          />

          {!!userInfo?.authentic && (
            <div
              className="lg:h-[56px] h-[32px] lg:px-[12px] lg:py-[10px] rounded-[16px] md:flex gap-[16px] lg:bg-main-white dark:lg:bg-bg-button-2 cursor-pointer items-center hidden opacity-100 active:opacity-60"
              onClick={() => onNavigateCreate(CreateType.CREATE_POST)}
            >
              <div className="bg-main-01 px-[16px] h-full rounded-[12px] flex">
                <IconCreatePost className="m-auto" />
              </div>
              <p className="text-main-01 text-[16px] Nunito-600 hidden lg:block dark:text-main-white">
                {t("common.create_post")}
              </p>
            </div>
            // <DropdownMenu
            //   open={showCreate}
            //   onOpenChange={() => setShowCreate(false)}
            // >
            //   <DropdownMenuTrigger asChild>
            //     <div
            //       className="lg:h-[56px] h-[32px] lg:px-[12px] lg:py-[10px] rounded-[16px] md:flex gap-[16px] lg:bg-main-white dark:lg:bg-bg-button-2 cursor-pointer items-center hidden opacity-100 active:opacity-60"
            //       onClick={() => setShowCreate(!showCreate)}
            //     >
            //       <div className="bg-main-01 px-[16px] h-full rounded-[12px] flex">
            //         <IconCreatePost className="m-auto" />
            //       </div>
            //       <p className="text-main-01 text-[16px] Nunito-600 hidden lg:block dark:text-main-white">
            //         {t("common.create_post")}
            //       </p>
            //     </div>
            //   </DropdownMenuTrigger>
            //   <DropdownMenuContent align="start" className="min-w-[183px]">
            //     <DropdownMenuItem
            //       className="focus:bg-bg-disable dark:focus:bg-white/10 focus:text-main-01 bold-600--700 text-sm text-center flex text-bg-01 dark:text-white px-4 py-3 group rounded-[8px]"
            //       onClick={() => onNavigateCreate(CreateType.CREATE_POST)}
            //       onMouseEnter={() => setHoverIcon(true)}
            //       onMouseLeave={() => setHoverIcon(false)}
            //     >
            //       <svg
            //         width="22"
            //         height="22"
            //         viewBox="0 0 22 22"
            //         fill="none"
            //         xmlns="http://www.w3.org/2000/svg"
            //         className="mr-[24px]"
            //       >
            //         <path
            //           fillRule="evenodd"
            //           clipRule="evenodd"
            //           d="M1.75 5C1.75 3.20507 3.20507 1.75 5 1.75H11C11.4142 1.75 11.75 1.41421 11.75 1C11.75 0.585786 11.4142 0.25 11 0.25H5C2.37665 0.25 0.25 2.37665 0.25 5V17C0.25 19.6234 2.37665 21.75 5 21.75H17C19.6234 21.75 21.75 19.6234 21.75 17V11C21.75 10.5858 21.4142 10.25 21 10.25C20.5858 10.25 20.25 10.5858 20.25 11V17C20.25 18.7949 18.7949 20.25 17 20.25H5C3.20507 20.25 1.75 18.7949 1.75 17V5ZM15.419 1.67708C16.3218 0.774305 17.7855 0.774305 18.6883 1.67708L20.3229 3.31171C21.2257 4.21449 21.2257 5.67818 20.3229 6.58096L18.8736 8.03028C18.7598 7.97394 18.6401 7.91302 18.516 7.84767C17.6806 7.40786 16.6892 6.79057 15.9493 6.05069C15.2095 5.31082 14.5922 4.31945 14.1524 3.48403C14.087 3.35989 14.0261 3.24018 13.9697 3.12639L15.419 1.67708ZM14.8887 7.11135C15.7642 7.98687 16.8777 8.67594 17.7595 9.14441L12.06 14.8438C11.7064 15.1975 11.2475 15.4269 10.7523 15.4977L7.31963 15.9881C6.5568 16.097 5.90295 15.4432 6.01193 14.6804L6.50231 11.2477C6.57305 10.7525 6.80248 10.2936 7.15616 9.93996L12.8556 4.24053C13.3241 5.12234 14.0131 6.23582 14.8887 7.11135Z"
            //           fill={
            //             themeApp === "dark"
            //               ? "#fff"
            //               : hoverIcon
            //               ? "#7673E5"
            //               : "#100D28"
            //           }
            //         />
            //       </svg>

            //       {t("news.option_create_title", {
            //         field: t("news.post")
            //       })}
            //     </DropdownMenuItem>
            //     <DropdownMenuItem
            //       className="focus:bg-bg-disable dark:focus:bg-white/10 focus:text-main-01 bold-600--700 text-bg-01 dark:text-white text-sm text-center flex px-4 py-3 rounded-[8px]"
            //       onClick={() => onNavigateCreate(CreateType.CREATE_EVENT)}
            //     >
            //       <svg
            //         width="24"
            //         height="24"
            //         viewBox="0 0 24 24"
            //         fill="none"
            //         xmlns="http://www.w3.org/2000/svg"
            //         stroke="currentColor"
            //         strokeWidth="1.25"
            //         strokeLinecap="round"
            //         strokeLinejoin="round"
            //         className="mr-[24px]"
            //       >
            //         <path d="M8 2V5" />
            //         <path d="M16 2V5" />
            //         <path d="M3 7.5C3 5.29086 4.79086 3.5 7 3.5H17C19.2091 3.5 21 5.29086 21 7.5V18C21 20.2091 19.2091 22 17 22H7C4.79086 22 3 20.2091 3 18V7.5Z" />
            //         <path d="M3 9H21" />
            //         <path d="M12 12V18" />
            //         <path d="M15 15L9 15" />
            //       </svg>
            //       {t("news.option_create_title", {
            //         field: t("news.event")
            //       })}
            //     </DropdownMenuItem>
            //   </DropdownMenuContent>
            // </DropdownMenu>
          )}
        </div>
        <div id="header-center-side" className="flex flex-1 w-full">
          <MenuBar />
        </div>
        <div
          id="header-right-side"
          className="flex items-center md:gap-[24px] md:max-w-[150px] sd:max-w-[372px] w-full justify-end"
        >
          <IconSearch
            onClick={() => router.push("/search")}
            className="cursor-pointer"
          />
          <div className="hidden lg:inline-block">
            <SelectTheme />
          </div>
          {!!isLogin ? (
            <>
              <NotificationHeader />
              <div className="hidden md:flex">
                <AccountHeader />
              </div>
            </>
          ) : (
            <Button
              variant="light"
              className="lg:w-[150px] lg:h-[52px] hidden md:block md:text-[14px] lg:text-[16px] dark:bg-bg-button-2 h-[38px] w-[140px]"
              onClick={() => setOpenModal(OPEN_MODAL.LOGIN_MODAL)}
            >
              {t("common.login")}
            </Button>
          )}
        </div>
      </header>
    </div>
  )
}
