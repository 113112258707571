import { IMerchandiseAddress } from "@/app/interfaces"
import { OptionMerchandiseAddress } from "./OptionMerchandiseAddress"
import { useTranslation } from "next-i18next"

export const OneItemAddress = ({
  border,
  address,
  onSetAddressDefault,
  onDeleteAddress,
  onUpdateAddress
}: {
  border: boolean
  address: IMerchandiseAddress
  onSetAddressDefault: (address: IMerchandiseAddress) => void
  onDeleteAddress: (id: string) => void
  onUpdateAddress: (address: IMerchandiseAddress) => void
}) => {
  const { t } = useTranslation()
  return (
    <div
      className={`w-full space-y-[6px] py-[16px] ${
        border ? "border-b border-black/10" : ""
      }`}
    >
      <div className="flex items-center justify-between gap-[12px] group">
        <p className="text-base text-bg-01 Nunito-600 dark:text-white flex-1">
          {address.fullname}
        </p>
        <div className="flex items-center gap-[12px]">
          {address.default ? (
            <div className="rounded-[8px] bg-bg-disable dark:bg-white/10  h-[24px] px-[12px] text-main-01 text-xs flex items-center justify-center">
              {t("merchandise.default_address")}
            </div>
          ) : (
            <></>
          )}
          <div className="relative w-fit">
            <OptionMerchandiseAddress
              item={address}
              onSetAddressDefault={onSetAddressDefault}
              onDeleteAddress={onDeleteAddress}
              onUpdateAddress={onUpdateAddress}
            />
          </div>
        </div>
      </div>
      <p className="text-sm text-neutral-05 Nunito-500">{address.phone}</p>
      <p className="text-sm text-neutral-05 Nunito-500">
        {address.address}, {address.ward}, {address.district}, {address.city}
      </p>
    </div>
  )
}
